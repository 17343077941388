import React, { useEffect } from "react";
import AOS from "aos";
import "./styles.scss";
import logo from "../../images/banner-1.png";

import tw from "../../images/public (1).png";
import dc from "../../images/public.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<div className="footer-wrapper">
			<div
				className="footer-content border-t-2 border-white/40"
				data-aos="zoom-in"
			>
				<div className="grid grid-cols-3  py-10 md:grid-cols-2 lg:grid-cols-6 gap-10 justify-center ">
					<div className="flex flex-col justify-between items-baseline space-y-2 col-span-3 lg:col-span-3">
						<img src={logo} alt="" className="logo h-20" />
						<div className="link hidden lg:block">
							© Copyright 2023 Sauce Proxies
						</div>
					</div>
					<div className="flex flex-col space-y-2">
						<div className="link font-semibold mb-2 cursor-pointer">About</div>
						<HashLink className="link" smooth to="/#home">
							Home
						</HashLink>
						<HashLink smooth className="link" to="/#features">
							Purchase
						</HashLink>

						<HashLink smooth className="link" to="/#faq">
							FAQ
						</HashLink>
						<a
							href="https://dashboard.sauceproxies.com/"
							target="_blank"
							className="link"
							rel="noopener noreferrer"
						>
							Dashboard
						</a>
					</div>
					<div className="flex flex-col space-y-2 ">
						<div className="link font-semibold mb-2 cursor-pointer">Other</div>
						<Link to="/tos">Terms</Link>
						<Link to="/privacy">Privacy</Link>
					</div>

					<div className="flex flex-col space-y-2">
						<div className="link font-semibold md:text-center mb-2 cursor-pointer">
							Social
						</div>
						<div className="flex items-center justify-center  space-x-3">
							<a
								href="https://twitter.com/SauceProxy"
								target="_blank"
								className="transform transition duration-500 hover:scale-110 "
								rel="noopener noreferrer"
							>
								<img src={tw} alt="" className="cursor-pointer" />
							</a>

							<a
								href="https://discord.gg/J3jG9qx988"
								target="_blank"
								className="transform transition duration-500 hover:scale-110 "
								rel="noopener noreferrer"
							>
								<img src={dc} alt="" className="cursor-pointer" />
							</a>
						</div>
					</div>
				</div>
				<div className="link text-center mt-5  lg:hidden">
					© Copyright 2023 Sauce Proxies
				</div>
			</div>
		</div>
	);
}
