import React from "react";
import "./styles.scss";

export default function PP() {
	return (
		<div className="pp">
			<div>
				<h1>Secret Sauce Group LLC Privacy Policy</h1>
				<br />
				<br />
				Secret Sauce Group LLC Privacy Policy
				<br />
				<br />
				Last Updated: July 1, 2021
				<br />
				<br />
				The Secret Sauce Group, LLC ("we," "us," or "our") respects your privacy
				and knows that you care about protecting your personal information. This
				privacy policy identifies what information we collect, use, share from
				you when you visit or make a purchase from
				https://www.secretsaucegroup.com (the "Site," including all subdomains)
				and the services made available on it (the "Services") and explains how
				we may use or share that information. We will only use and share your
				information as described in this privacy policy.
				<br />
				<br />
				PLEASE READ THIS PRIVACY POLICY CAREFULLY. THE TERMS STATED IN THIS
				PRIVACY POLICY CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU AND THE
				SECRET SAUCE GROUP, LLC. BY USING THIS SITE AND THE RELATED SERVICES,
				YOU UNCONDITIONALLY AGREE TO BE BOUND BY THE TERMS STATED IN THIS
				PRIVACY POLICY, INCLUDING ALL EXCLUSIONS AND LIMITATIONS OF LIABILITY,
				AND WARRANT THAT YOU HAVE FULL AUTHORITY AND CAPACITY, LEGAL AND
				OTHERWISE, TO USE THE SERVICES. YOU MAY NOT ACCESS OR USE THIS SITE OR
				THE SERVICES IF YOU DO NOT AGREE TO ANY PART OF THESE TERMS. WE RESERVE
				THE RIGHT TO PERIODICALLY MAKE UPDATES TO THIS PRIVACY POLICY AS OUR
				PRACTICES CHANGE. YOUR CONTINUED USE OF THE SITE AFTER SUCH CHANGES
				CONSTITUTES YOUR ACCEPTANCE OF THE CHANGES, SO PLEASE CHECK BACK
				PERIODICALLY FOR UPDATES.
				<br />
				<br />
				PERSONAL INFORMATION WE COLLECT
				<br />
				<br />
				This Site primarily operates as a(n) e-commerce website. When you visit
				the Site, we automatically collect certain information about your
				device, including information about your web browser, IP address, time
				zone, and some of the cookies that are installed on your device. This
				privacy policy also applies to information we collect from you in email,
				text, and other electronic correspondence; and through any mobile or
				desktop application through which we may communicate. This privacy
				policy does not apply to information we collect offline or that any
				third party collects from you after you follow links on the Site,
				including any advertising and affiliate links.
				<br />
				<br />
				Additionally, as you browse the Site, we collect information about the
				individual web pages or products that you view, what websites or search
				terms referred you to the Site, and information about how you interact
				with the Site. We refer to this automatically-collected information as
				"Device Information."
				<br />
				<br />
				We collect Device Information using the following technologies:
				<br />
				<br />
				- "Cookies" are data files that are placed on your device or computer
				and often include an anonymous unique identifier. For more information
				about cookies, and how to disable cookies, visit
				http://www.allaboutcookies.org.
				<br />
				- "Log files" are information that is commonly collected by host servers
				when you visit website. Log files track actions occurring on the Site,
				and collect data including your internet protocol (IP address), browser
				type, Internet service provider, referring/exit pages, domain names,
				landing pages, page view, click counts, and date/time stamps and similar
				information. <br />
				- "Web beacons," "tags," and "pixels" are electronic files used to
				record information about how you browse the Site.
				<br />
				<br />
				Additionally, when you make a purchase or attempt to make a purchase
				through the Site, we collect certain information from you, including
				your name, billing address, shipping address, payment information
				(including credit card numbers), email address, and phone number. We
				refer to this information as "Order Information." <br />
				<br />
				We may also track other details regarding your activities on the Site
				and Services, including your equipment, operating system, software,
				traffic information, and location data.
				<br />
				<br />
				Upon signing up with our service we also collect your social media
				account names including but not limited to: Twitter lnstagram Discord
				<br />
				<br />
				HOW DO WE USE YOUR PERSONAL INFORMATION?
				<br />
				<br />
				We use the Order Information that we collect generally to fulfill any
				orders placed through the Site, including but not limited to, processing
				your payment information, arranging for shipping, and providing you with
				invoices and/or order confirmations). Additionally, we use this Order
				Information to:
				<br />
				<br />
				To administer the Site and enforce our rules and policies.
				<br />
				Communicate with you.
				<br />
				Screen our orders for potential risk or fraud
				<br />
				To customize Site content and Services according to your preferences.
				<br />
				To provide access to Site content and Services to you.
				<br />
				To improve the Site and Services.
				<br />
				To diagnose technical or Service-related problems.
				<br />
				To maintain security over your information, the Site, and its contents.
				<br />
				To fulfill any requests for information, products, or Services.
				<br />
				To carry out our responsibilities and enforce any contracts between you
				and us.
				<br />
				For any other use as we may indicate at the time you provide the
				information.
				<br />
				To fulfill any other purpose with your consent.
				<br />
				<br />
				All information collected from visitors of the Site belongs to us,
				whether or not such information is personal information, information you
				volunteer, or information passively collected about you. As such, such
				information may be subject to transfer to a new owner in the event of
				our bankruptcy, sale, or liquidation.
				<br />
				<br />
				<br />
				<br />
				SHARING YOUR PERSONAL INFORMATION
				<br />
				<br />
				We may share your personal information as follows:
				<br />
				<br />
				With our affiliates, including any subsidiaries we now own or later
				acquire.
				<br />
				With our trusted employees, contractors, business partners, service
				providers, suppliers, vendors, and other third parties that support us,
				which may include sending you emails on our behalf; analyzing your
				activities and interactions on the Site in order to make improvements to
				the Site, Services, and experiences of our visitors; and providing
				support to the Site, the Services, our operations, or fulfilling your
				requests. These third parties may only use your information in order to
				provide such support and are contractually required to maintain the
				confidentiality of your information. For example, we may share your
				information with credit card processing companies in order to bill
				visitors for goods or services.
				<br />
				With a buyer, successor, or other third party due to a merger,
				restructuring, reorganization, or other transfer or disposition of The
				Secret Sauce, LLC or our operations, including, but not limited to, in
				the event of a sale, bankruptcy, dissolution, or similar proceeding. In
				such event, we will provide you with prior notification if your
				information will become subject to a different privacy policy.
				<br />
				In response to court orders, subpoenas, or legal processes, such as
				requests from government regulatory or law enforcement agencies; to
				defend, pursue, or participate in legal actions or proceedings or
				exercise a legal right; to take precautions against actual or potential
				liabilities; to investigate or respond to potential or suspected illegal
				activities, fraud, or threats to the Site, its visitors, or the property
				or physical safety of any person; to enforce or investigate violations
				of our rules; or as otherwise permitted or required by applicable law.
				<br />
				With those that we so indicate at the time you provide the information.
				<br />
				To third parties when we have your consent or when we have parental
				consent if the visitor is a minor.
				<br />
				<br />
				We use your Personal Information to provide you with targeted
				advertisements or marketing communications we believe may be of interest
				to you. In the case we do market to you via email you will be able to
				opt out at anytime.
				<br />
				<br />
				We may store your information in locations outside of our direct
				control; for instance, on servers or databases where the hosting
				provider is located. Additionally, we may share or sell aggregate
				information about our visitors that does not personally identify
				individuals with our partners, affiliates, advertisers, and other third
				parties without restriction. This information may include information
				that we have changed so that individuals are no longer personally
				identifiable. We may also share this information with third parties in
				order to analyze visitor data, activities, and usage of the Site or
				Services in order to make improvements to the Site, Services, and
				experiences of our visitors.
				<br />
				<br />
				If you choose to participate in any promotions or similar events through
				the Site or Services, the rules or terms and conditions for such events
				may change the way we share your information with third parties. Please
				review the applicable rules or terms and conditions for all such events
				in which you participate.
				<br />
				<br />
				DO NOT TRACK
				<br />
				<br />
				Please note that we do not alter our Site's data collection and use
				practices when we see a Do Not Track signal from your browser.
				<br />
				<br />
				If you are a European resident, you have the right to access personal
				information we hold about you and to ask that your personal information
				be corrected, updated, or deleted. If you would like to exercise this
				right, please contact us through the contact information below.
				<br />
				<br />
				Additionally, if you are a European resident, we note that we are
				processing your information in order to fulfill contracts we may have
				with vou (for example if vou make an order through the Site.
				Additionally, please note that your information will be transferred
				outside of Europe, including to Canada and the United States.
				<br />
				<br />
				OPT OUT
				<br />
				<br />
				As your privacy is important to us, we provide you with the following
				procedure(s) for opting out of future communications from us:
				<br />
				<br />
				Email. Send an email to{" "}
				<a href="mailto:support@secretsaucegroup.com">
					support@secretsaucegroup.com
				</a>{" "}
				explaining the specific communications or privacy practices you want to
				opt out of. You may also opt out by clicking "unsubscribe," or a similar
				button, at the bottom of any emails we may send you and then following
				the online instructions. However, please note that it may not be
				possible to opt out of certain emails (for example, confirmation emails
				related to services you have requested).
				<br />
				Phone. Call ___________ to receive help from our customer support team
				to opt out.
				<br />
				<br />
				NOTICE OF CALIFORNIA PRIVACY RIGHTS
				<br />
				<br />
				Pursuant to California Civil Code Section 1798.83, California residents
				who use our Site may request certain information regarding any
				disclosure of personal information to third parties for their direct
				marketing purposes. To make this request, please email us at _{" "}
				<a href="mailto:support@secretsaucegroup.com">
					support@secretsaucegroup.com
				</a>{" "}
				or use the contact information provided below at the end of this policy.
				Should you choose to email us, please include in the subject line or
				body of your email the phrase "California Customer Choice Privacy
				Notice" and specify the personal information you do not wish to be
				shared with third parties for their direct marketing purposes. Please
				allow up to thirty (30) days for a response.
				<br />
				<br />
				DATA RETENTION
				<br />
				<br />
				When you place an order through the Site, we will maintain your Order
				Information for our records unless and until you ask us to delete this
				information.
				<br />
				<br />
				<br />
				<br />
				AGE
				<br />
				<br />
				The Site is not intended for individuals under the age of 13. If you are
				under the age of 13, you must ask your parent or legal guardian for
				permission to use this Site. This Site knowingly collects personal
				information from children under 13 years of age.
				<br />
				<br />
				The information we collect and use from children under 13 years of age
				may include, but is not limited to, name, address, email address, phone
				number, gender, birth date, device identifiers, geolocation information,
				IP address, and any other information the child shares with us. We
				collect this information directly when your child submits it on the
				Site. We will not require your child to disclose more information than
				is reasonably necessary to use a Service or engage in an activity on the
				Site.
				<br />
				<br />
				We encourage you to monitor your child's use of the Site. Your child may
				have access to all our Services. However, we will not contact your child
				with promotions or marketing communications without a parent's or legal
				guardian's permission. Our collection, use, and sharing of information
				collected from visitors, including your child, is discussed throughout
				this privacy policy. If you do not agree to such collection, use, or
				sharing, please do not let your child use this Site. Additionally, to
				view, correct, or delete your child's personal information, see "Opt-Out
				Procedures" above. Please inform us if you want us to stop any further
				collection or use of your child's information.
				<br />
				<br />
				<br />
				<br />
				CHANGES
				<br />
				<br />
				We may update this privacy policy from time to time in order to reflect,
				for example, changes to our practices or for other operational, legal or
				regulatory reasons. If we make significant changes to the way we manage
				our visitors' information, we will notify you by email or by posting
				prominent notice on our Site. It is your responsibility to ensure we
				have your current email address and to periodically check this page for
				any updates.
				<br />
				<br />
				CONTACT US
				<br />
				<br />
				For more information about our privacy practices, if you have questions,
				or if you would like to make a complaint, please contact us by e-mail at{" "}
				<a href="mailto:support@secretsaucegroup.com">
					support@secretsaucegroup.com
				</a>{" "}
				or by mail using the details provided below:
				<br />
				<br />
				34522 N Scottsdale Rd
				<br />
				Suite 120-278
				<br />
				Scottsdale, AZ 85266
			</div>
		</div>
	);
}
