import React, { useState } from "react";

const MyAccordion = ({ element }) => {
	const { question, answer } = element;
	const [show, setShow] = useState(false);
	return (
		<div
			className="answer-qestion  w-full cursor-pointer text-white py-3 "
			data-aos="fade-up"
		>
			<div
				className=" flex justify-between items-center"
				onClick={() => setShow(!show)}
			>
				<div className="question">{question}</div>
				{show ? <i class="fas fa-minus"></i> : <i class="fas fa-plus"></i>}
			</div>
			{show && <p className="text-start mt-2">{answer}</p>}
		</div>
	);
};

export default MyAccordion;
