export const questions = [
	{
		id: 1,
		question: "What are residential Proxies?",
		answer:
			"Residential proxies are home IP's like yours. Sauce proxies are authentic IP addresses from around the world.",
	},
	{
		id: 2,
		question: "Residential or ISP Proxies?",
		answer:
			"ISPs are best for fast and authentic internet connections. For general purposes that require anonymity, Residential Proxies may be the better option.",
	},
	{
		id: 3,
		question: "When does my residential data expire?",
		answer:
			"There's no expiry on when your data runs out. You can use it until you have consumed all of your traffic.",
	},
	{
		id: 4,
		question: "How many residential proxies can I generate?",
		answer:
			"You can generate an unlimited amount of proxies. The only thing that limits you is your amount of Data.",
	},
	{
		id: 5,
		question: "How fast do the orders get delivered?",
		answer: "Orders are delivered instantly most of the time.",
	},
];
