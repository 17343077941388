import React, { useState } from "react";
import PlanCard from "./PlanCard/PlanCard";
import "./styles.scss";
import circle from "../../images/Group 41.png";
import arrow from "../../images/Group 42.png";
import squre from "../../images/index-path19@2x.png.png";

export default function Plans() {
	const [clicked, setClicked] = useState("Residential");
	return (
		<div className="relative">
			<div className="absolute hidden lg:block top-1/2 -translate-y-1/2 pl-3">
				<div className="flex flex-col items-center h-96 justify-evenly">
					<img src={arrow} alt="" />
					<img src={squre} alt="" />
					<img src={circle} alt="" />
				</div>
			</div>
			<div className="absolute right-0 hidden lg:block top-1/2 -translate-y-1/2 pr-3">
				<div className="flex flex-col items-end h-96 justify-evenly">
					<img src={circle} alt="" />

					<img src={arrow} alt="" />
				</div>
			</div>

			<div className="plans-wrapper" id="pricing">
				<div className="plans-container">
					<h4>Our Products</h4>

					<div className="tabs-container">
						<button
							onClick={() => setClicked("Residential")}
							className={clicked === "Residential" ? "selected" : ""}
						>
							Residential
						</button>
						<button
							onClick={() => setClicked("isp")}
							className={clicked === "isp" ? "selected" : ""}
						>
							ISPs
						</button>
						<button
							className={clicked === "subnets" ? "selected" : ""}
							onClick={() => setClicked("subnets")}
						>
							Subnets
						</button>
						<button>
							<a rel="noopener noreferrer">Servers</a>
						</button>
					</div>
					{clicked === "Residential" && (
						<div className="content">
							{[
								{
									heading: "Monthly",
									title: "Elite Residential Proxies",

									l1: "Private pools with 120 Million+ Ultra Premium IPs",
									l2: " Unbanned on all sites",
									l3: " Best for ANY USE CASE!",
									l4: " Optimized for ALL COUNTRIES",
									l5: "City and country-level targeting",
									opt1: "1 GB",
									opt2: "2 GB",
									opt3: "5 GB",
									opt4: "10 GB",
									p1: 22,
									p2: 44,
									p3: 110,
									p4: 220,
								},
								{
									heading: "Monthly",
									title: "Premium Residential Proxies",

									l1: "Private pools with 10 Million+ Premium IPs",
									l2: "Unbanned on all sites",
									l3: "Best for ANY USE CASE!",
									l4: "Optimized for ALL COUNTRIES",

									opt1: "1 GB",
									opt2: "2 GB",
									opt3: "5 GB",
									opt4: "10 GB",
									p1: 15,
									p2: 30,
									p3: 75,
									p4: 150,
								},
								{
									heading: "Monthly",
									title: "Basic Residential Proxies",

									l1: " Private pools with 10 Million+ Clean IPs",
									l2: " Unbanned on all sites",
									l3: " Best for low-security sites",
									l4: " Optimized for ALL COUNTRIES",

									opt1: "1 GB",
									opt2: "2 GB",
									opt3: "5 GB",
									opt4: "10 GB",
									p1: 10,
									p2: 20,
									p3: 50,
									p4: 100,
								},
							].map((item, i) => (
								<PlanCard key={i} data={item} />
							))}
						</div>
					)}
					{clicked === "isp" && (
						<div className="content">
							{[
								{
									heading: "Monthly",
									title: "Elite USA ISPs",

									l1: " Premium USA IPs",
									l2: "100GP/s Uplink",
									l3: " As low as 10ms on servers!",
									l4: " Hosted in Ashburn, VA",
									l5: "All Virgin IPs",
									l6: "Dedicated Baremetal Server",
									opt1: "10 Proxies",
									opt2: "25 Proxies",
									opt3: "50 Proxies",
									opt4: "100 Proxies",
									p1: 18,
									p2: 37.5,
									p3: 76,
									p4: 150,
								},
								{
									heading: "Monthly",
									title: "Nike & Retail ISPs",

									l1: " Premium USA IPs",
									l2: " 100GP/s Uplink",
									l3: "As low as 10ms on servers!",
									l4: "Hosted in Ashburn VA",
									l5: "Dedicated Baremetal Server",
									opt1: "10 Proxies",
									opt2: "25 Proxies",
									opt3: "50 Proxies",
									opt4: "100 Proxies",
									p1: 15,
									p2: 45,
									p3: 90,
									p4: 180,
								},
								{
									heading: "Monthly",
									title: "Elite UK ISPs",

									l1: " Premium EU IPs",
									l2: " 100GP/s Uplink",
									l3: " Virgin Media ISPs",
									l4: " As low as 10ms on servers!",
									l5: "Hosted in the UK",
									l6: "All Virgin IPs",
									l7: "Dedicated Baremetal Server",
									opt1: "10 Proxies",
									opt2: "25 Proxies",
									opt3: "50 Proxies",
									opt4: "100 Proxies",
									p1: 18,
									p2: 45,
									p3: 90,
									p4: 180,
								},
								{
									heading: "Monthly",
									title: "Elite DE ISPs",

									l1: " Premium EU IPs",
									l2: " 100GP/s Uplink",
									l3: " DTAG ISPs",
									l4: " As low as 10ms on servers!",
									l5: "Hosted in Germany",
									l6: "All Virgin IPs",
									l7: "Dedicated Baremetal Server",
									opt1: "10 Proxies",
									opt2: "25 Proxies",
									opt3: "50 Proxies",
									opt4: "100 Proxies",
									p1: 18,
									p2: 45,
									p3: 90,
									p4: 180,
								},
							].map((item, i) => (
								<PlanCard key={i} data={item} />
							))}
						</div>
					)}

					{clicked === "subnets" && (
						<div className="content">
							{[
								{
									heading: "Monthly",
									title: "Elite USA ISPs",

									l1: " Premium USA IPs",
									l2: " 100GP/s Uplink",
									l3: " As low as 10ms on servers!",
									l4: "Hosted in Ashburn, VA",
									l5: "All Virgin IPs",
									l6: "Dedicated Baremetal Server",
									opt1: "254 Proxies (subnet)",
									p1: "457.2",
								},
								{
									heading: "Monthly",
									title: "Nike & Retail ISPs",

									l1: " Premium USA IPs",
									l2: " 100GP/s Uplink",
									l3: " As low as 10ms on servers!",
									l4: "Hosted in Ashburn VA",
									l5: "Dedicated Baremetal Server",

									opt1: "254 Proxies (subnet)",
									p1: "381",
								},
								{
									heading: "Monthly",
									title: "Elite UK ISPs",

									l1: " Premium EU IPs",
									l2: " 100GP/s Uplink",
									l3: " Virgin Media ISPs",
									l4: "As low as 10ms on servers!",
									l5: "Hosted in the UK",
									l6: "All Virgin IPs",
									l7: "Dedicated Baremetal Server",
									opt1: "254 Proxies (subnet)",
									p1: "457.2",
								},
								{
									heading: "Monthly",
									title: "Elite DE ISPs",

									l1: "Premium EU IPs",
									l2: "100GP/s Uplink",
									l3: "DTAG ISPs",
									l4: "As low as 10ms on servers!",
									l5: "Hosted in Germany",
									l6: "All Virgin IPs",
									l7: "Dedicated Baremetal Server",
									opt1: "254 Proxies (subnet)",
									p1: "457.2",
								},
							].map((item, i) => (
								<PlanCard key={i} data={item} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
