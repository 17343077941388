import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import circle from "../../images/Group 41.png";
import arrow from "../../images/Group 42.png";
import squre from "../../images/index-path19@2x.png.png";
import { HiChevronDown } from "react-icons/hi";
import "./FAQ.scss";
import { questions } from "./api";
import MyAccordion from "./MyAccordion";

export default function FAQ() {
	useEffect(() => {
		AOS.init();
	}, []);

	const [data, setData] = useState(questions);
	return (
		<div className="relative">
			<div className="absolute hidden lg:block top-1/2 -translate-y-1/2 pl-3">
				<div className="flex flex-col items-center h-96 justify-evenly">
					<img src={arrow} alt="" />
					<img src={squre} alt="" />
					<img src={circle} alt="" />
				</div>
			</div>
			<div className="absolute right-0 hidden lg:block top-1/2 -translate-y-1/2 pr-3">
				<div className="flex flex-col items-end h-96 justify-evenly">
					<img src={circle} alt="" />

					<img src={arrow} alt="" />
				</div>
			</div>
			<div className="faq-wrapper lg:px-14" id="faq">
				<div className="faq-container" data-aos="fade-up">
					<h5>
						<span>Frequently Asked</span> Questions
					</h5>
					<h2 className="mb-14">
						Get answers to the most commonly asked questions.
					</h2>

					{data.map((curElem) => {
						return <MyAccordion key={curElem.id} element={curElem} />;
					})}
				</div>
			</div>
		</div>
	);
}
