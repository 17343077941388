import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

import "./styles.scss";
import home from "../../images/index-add-2.png.png";
import { HashLink } from "react-router-hash-link";

export default function Home() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<div className="home-wrapper" id="home">
			<div className="home-container mt-20">
				<div className="right pb-20 lg:pb-0" data-aos="fade-up">
					<h6>Sauce Proxies</h6>
					<h3>The only proxy solution you will ever need.</h3>
					<p>
						Fast and high quality residential and ISP proxies at unbeatable
						prices
					</p>
					<div className="flex button space-x-3">
						<HashLink smooth to="/#pricing">
							<button>Get Started</button>
						</HashLink>
						<HashLink smooth to="/#pricing">
							<button id="outline">Pricing</button>
						</HashLink>
					</div>
				</div>
				<div className="left e" data-aos="fade-down">
					<img src={home} alt="" />
				</div>
			</div>
		</div>
	);
}
